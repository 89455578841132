<template>
<div>
    <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row>
    <!-- {{date_now}} -->
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20 pt-2">
            <v-col lg="12" sm="9" class="mx-auto">
                <checkmong-home-bar title="การนัดหมาย"></checkmong-home-bar>
                <v-row class="d-flex " style="flex-direction: row;">
                    <v-col lg="4" cols="12" style="text-align: end;">
                        <v-card class="" style="">
                            <v-card-text style="border-radius: 7px; padding-top: 25px">
                                <v-date-picker v-model="date_now" :events="date_book_list" event-color="bg-teal" locale="th-th" color="bg-purple" no-title full-width></v-date-picker>
                            </v-card-text>
                        </v-card>
                        <v-btn @click="$router.go()" icon depressed small color="white" style="height: 39px;">
                            <v-icon left>
                                sync
                            </v-icon>
                        </v-btn>
                        <v-icon left color="#20c997">noise_control_off</v-icon>มีนัดคนไข้
                    </v-col>

                    <v-col lg="8" cols="12">
                        รายการนัดหมาย
                        <v-divider></v-divider>
                        <v-container style="max-height: 300px" class="overflow-y-auto">
                            <v-row v-for="booking in filtered">
                                <v-col cols="12">
                                    <v-row>
                                        <v-col lg="2" cols="3">
                                            <!-- {{booking.booking_date}} -->
                                            {{new Date(booking.booking_date).toLocaleString('th-TH', { dateStyle: 'short'})}}
                                            <br>
                                            {{booking.booking_time}}
                                        </v-col>
                                        <v-col lg="10" cols="9">
                                            <v-card>
                                                <v-card-text style="border-left: 1px solid #1F98BD;"><span>{{booking.user_patient_name}}</span><br><span>{{'\n'+booking.provider_name}}</span></v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center " style="padding-top: 15px">
            <v-col lg="3" sm="8" class="mx-auto" style="text-align: left;">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
            </v-col>
    
        </v-row>
    
        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-left">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl ml-6">
                    <v-icon small>west</v-icon>
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
    <!-- {{booking_list}}
    <br>
    <br> -->
    <!-- {{filtered}} -->
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },
    data: () => ({
        booking_list: [],
        selected_booking_list: [],
        active_waiting: false,
        title: '',
        status: '',
        date_book_list: [],
        arrayEvents: null,
        date_now: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        // booking_list:[
        //     {time:'08:00',name:'ชัชวาลย์ รัตนโสภา'},
        //     {time:'09:00',name:'ชัชวาลย์ รัตนโสภา'},
        //     {time:'10:00',name:'ชัชวาลย์ รัตนโสภา'},
        //     {time:'11:00',name:'ชัชวาลย์ รัตนโสภา'},
        //     {time:'12:00',name:'ชัชวาลย์ รัตนโสภา'}
        //     ]
    }),

    mounted() {
        this.getBookingList()
        this.arrayEvents = [...Array(6)].map(() => {
            const day = Math.floor(Math.random() * 30);
            const d = new Date();
            d.setDate(day);
            return d.toISOString().substr(0, 10);
        });
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    computed: {
        filtered() {
            let text = this.date_now
            return this.booking_list.filter(item => {
                    return item.booking_date.indexOf(text) > -1
                })
        },
    },
    methods: {
        getDateBookList() {
            //console.log('--------------')
            //console.log(this.booking_list)
            const date_array = [];
            for (let x in this.booking_list) {
                date_array.push(this.booking_list[x]['booking_date']);

            }
            this.date_book_list = date_array
        },
        async getBookingList() {
            this.active_waiting = true
            this.title = 'กำลังอัพเดต',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_booking_record_provider', {
                "user_id": this.$store.state.login_detail.user_id,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response)
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.booking_list = response.data.booking_record_list;
                    this.getDateBookList()
                } else {
                    this.active_waiting = false
                }

            })

        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-main__wrap {
    padding-bottom: 10px;
}
</style>
